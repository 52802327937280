import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import { Divider } from "../../../../../lib/components/divider/divider";
import Photos from './photos.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The PSI Board of Governors is responsible for the long-term sustainability of the school as per the School’s Mission, Vision, Core Values, Parameters, and strategic direction. It is also responsible for the hiring, retention and annual evaluation of the school director, the maintenance of financial oversight, and any assistance that the school director may need in the provision of an ongoing quality school programme.`}</p>
    <Divider margin={'xxl'} transparent mdxType="Divider" />
    <Photos mdxType="Photos" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      